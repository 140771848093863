<template>
  <div id="app" name="app">
    <div class="right_nav">
      <ul>
        <li class="contact"><a target="_blank"  href="https://cschat.antcloud.com.cn/index.htm?tntInstId=LZg_oLSJ&scene=SCE00401300"><img src="./assets/imgs/kefu.png">联系客服</a></li>
        <!-- <li class="qrcode"><img src="./assets/imgs/qrcode.png">支付宝<br>二维码
          <span><img src="./assets/imgs/zfbQRcode.png"></span>
        </li> -->
        <li class="qrcode" @click="backtop"><img src="./assets/imgs/gotop.png">返回顶部</li>
      </ul>
    </div>
   
    
    <el-container>
      <!-- 顶部导航栏 -->
      <!-- <div class="topbar">
        <div class="nav">
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <el-button type="text" @click="login">登录</el-button>
              <span class="sep">|</span>
              <el-button type="text" @click="register = true">注册</el-button>
            </li>
            <li v-else>
              欢迎
              <el-popover placement="top" width="180" v-model="visible">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="logout">确定</el-button>
                </div>
                <el-button type="text" slot="reference">{{this.$store.getters.getUser.userName}}</el-button>
              </el-popover>
            </li>
            <li>
              <router-link to="/order">我的订单</router-link>
            </li>
            <li>
              <router-link to="/collect">我的收藏</router-link>
            </li>
            <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
              <router-link to="/shoppingCart">
                <i class="el-icon-shopping-cart-full"></i> 购物车
                <span class="num">({{getNum}})</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- 顶部导航栏END -->

      <!-- 顶栏容器 -->
      <el-header>
        
        <div class="header_w">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#409eff"
          router
        >
            <div class="logo">
              <router-link to="/">
                <img src="./assets/imgs/tqzlogo.png" alt />
              </router-link>
            </div>
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/goods">全部商品</el-menu-item>
            <el-menu-item index="/about">关于我们</el-menu-item>
            <div class="so">
              <el-input placeholder="请输入搜索内容" v-model="search">
                <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
              </el-input>
            </div>
            <ul class="login_wrap">
              <li v-if="!this.$store.getters.getUser">
                <el-button type="text" @click="login">登录</el-button>
              </li>
              <li v-else>
                <el-dropdown trigger="click" @command="handleDropdownClick">
                  <el-button type="text">{{ this.$store.getters.getUser }}<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="order"
                      >个人中心
                    </el-dropdown-item>
                    <el-dropdown-item command="logout"
                      >退出登录
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
            
        </el-menu>
      </div>
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 登录模块 -->
      <MyLogin></MyLogin>
      <!-- 注册模块 -->
      <MyRegister :register="register" @fromChild="isRegister"></MyRegister>

      <!-- 主要区域容器 -->
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div class="footer">
          <div class="ng-promise-box">
            <div class="ng-promise">
              <p class="text">
                <a class="icon1"  href="tel:400-888-9127">客服热线：400-888-9127</a>
                <a class="icon2" href="javascript:;">服务时间：工作日 9:30~12:00；13:30~18:30</a>
                <a class="icon3" style="margin-right: 0" href="javascript:;">官方支付宝小程序：淘气租<br></a>
              </p>
            </div>
          </div>
          <div class="mod_help">
            <p>
              <router-link to="/">首页</router-link>
              <span>|</span>
              <router-link to="/goods">全部商品</router-link>
              <span>|</span>
              <router-link to="/about">关于我们</router-link>
            </p>
            <p class="coty">Copyright©瑞霖贸易（浙江）有限公司 &copy; <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2020038665号-1</a></p>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      activeIndex: "", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false // 是否退出登录
    };
  },
  created() {
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(JSON.parse(localStorage.getItem("user")));
    }
  },
  computed: {
    ...mapGetters(["getUser", "getNum"])
  },
  watch: {
    // 获取vuex的登录状态
  //   getUser: function(val) {
  //     if (val === "") {
  //       // 用户没有登录
  //       this.setShoppingCart([]);
  //     } else {
  //       // 用户已经登录,获取该用户的购物车信息
  //       this.$axios
  //         .post("/api/user/shoppingCart/getShoppingCart", {
  //           user_id: val.user_id
  //         })
  //         .then(res => {
  //           if (res.data.code === "001") {
  //             // 001 为成功, 更新vuex购物车状态
  //             this.setShoppingCart(res.data.shoppingCartData);
  //           } else {
  //             // 提示失败信息
  //             this.notifyError(res.data.msg);
  //           }
  //         })
  //         .catch(err => {
  //           return Promise.reject(err);
  //         });
  //     }
  //   }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
    },
    // 退出登录
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("user", "");
      // 清空vuex登录信息
      this.setUser("");
      this.notifySucceed("成功退出登录");
      this.$router.push({ path: "/",});

    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function () {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop;
        const speed = Math.floor(-top / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed;

        if (top === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != "") {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: "/goods", query: { search: this.search } });
        this.search = "";
      }
    },
    handleDropdownClick(name) {
      switch (name) {
        case "order":
          this.$router.push("/order");
          break;
        case "logout":
          this.logout();
          break;
        default:
          break;
      }
    },
  }
};
</script>

<style>
@import "/assets/css/index.css";

</style>